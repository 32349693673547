import Completion from "../gpt/Completion";
import { useEffect, useState } from "react";
import { useHarderState } from "../HarderProvider";
import Markdown from "react-markdown";
import { appInsights } from "../appInsights";
import { GetPrompt } from "../prompts/inventory";
import { getAccessToken } from "../authConfig";
import { useMsal } from "@azure/msal-react";

import { hashCode } from "../results/SerpList"


function QueryPrimes() {
    const [harder, dispatch] = useHarderState();

    const [qprimes, setQprimes] = useState(null);
    const [prompt, setPrompt] = useState(null);

    const { instance, accounts } = useMsal();
        
    useEffect(() => {
        // check dependencies first
        if (harder.original_results.length === 0) return;
        if (!harder.google_results && !harder.disableGoogle) return;
        if (!harder.ltProfile && !harder.disablePersonalization) return;
        if (!harder.sessionIntent && !harder.disablePersonalization) return;
        if (!harder.intent) return;

        GetPrompt("qprimes", harder.prompts.qprimes).then(([prompt_func, parser_func]) => {
            setPrompt(prompt_func(harder));
        });
    }, [harder.prompts.qprimes, harder.original_results, harder.google_results, harder.disableGoogle, harder.ltProfile, harder.sessionIntent, harder.disablePersonalization, harder.intent]);

    const nextQueries = qprimes => {
        dispatch({type: 'set', key: 'qprimes', value: qprimes})

        GetPrompt("qprimes", harder.prompts.qprimes).then(([prompt_func, parser_func]) => {
            getAccessToken({instance, accounts})
            .then(token => {
                const qlist = parser_func(qprimes);
                for (let qprime of qlist) {
                    appInsights.trackEvent({name: "Bing API qprime call"})

                    // TODO this should respect the get results from setting
                    const helpers = process.env.REACT_APP_HELPERS_URL ||  (window.location.protocol + "//" + window.location.host + "/helpers");
                    fetch(
                        `${helpers}/bingserp?q=${encodeURIComponent(qprime)}&mkt=${harder.language}-${harder.region}`,
                        {
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token.accessToken}` 
                            }
                        }
                    )
                    .then(r => r.json())
                    .then(r => {
                        for (let result of r.algos) {
                            result.qprime = qprime
                            result.urlhash = hashCode(result.url)
                            dispatch({type: 'addResult', result: result})
                        }
                    })
                }
            })
        })
    }

    return (
        <>
            {(harder.qprimes || qprimes) && <p>Also searching for</p>}
            {prompt && <Completion name="qprimes" prompt={prompt} prefix="- " onReceive={setQprimes} onDone={nextQueries}></Completion>}
            <Markdown>{harder.qprimes || qprimes}</Markdown>
        </>
    );
}

export default QueryPrimes;
