import { inventory } from "./inventory";
import { useHarderState } from "../HarderProvider";
import { useState } from 'react'

function PromptPicker() {
    const [harder, dispatch] = useHarderState();
    const scenarios = Object.keys(inventory);
    const [localState, setLocalState] = useState(harder.prompts);

    return (<>
        {scenarios.map(scenario =>
            <table key={scenario}>
                <tbody>
                    <tr>
                        <td>{scenario}</td>
                        <td>
                            <input value={localState[scenario]} onChange={e => setLocalState(prev => ({ ...prev, [scenario]: e.target.value }))} list={`inventory-${scenario}`}></input>
                            <datalist id={`inventory-${scenario}`}>{inventory[scenario].map(item => <option value={item} key={item}></option>)}</datalist>
                            <button disabled={!inventory[scenario].includes(localState[scenario])} onClick={() => dispatch({ type: 'setPrompt', key: scenario, value: localState[scenario] })}>Set</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        )}
    </>
    )
}

export default PromptPicker;