/* global chrome */
import './SerpResult.css'
import { useState, useEffect } from 'react';
import Completion from "../gpt/Completion";
import { useHarderState } from '../HarderProvider';
import { GetPrompt } from '../prompts/inventory';
import { appInsights } from "../appInsights";

function SerpResult({url, title, snippet, urlhash}) {
    const [fullBody, setFullBody] = useState(null);

    const [captionPrompt, setCaptionPrompt] = useState(null);
    const [, setGencaption] = useState(null);
    const [, setGencaptionTitle] = useState(null);
    const [, setGencaptionSnippet] = useState(null);

    const [dcgPrompt, setDcgPrompt] = useState(null);
    const [, setDcg] = useState(null);
    const [harder, dispatch] = useHarderState();

    useEffect(() => {
        if (chrome.runtime) {
            chrome.runtime.sendMessage(process.env.REACT_APP_EXTENSION_ID, { type: 'startFetch', url: url });
            const checker = setInterval(() => {
                chrome.runtime.sendMessage(process.env.REACT_APP_EXTENSION_ID, { type: 'checkFetch', url: url}, response => {
                    if (response) {
                        clearInterval(checker);
                        setFullBody(response);

                        dispatch({type: "setResultDetail", url: url, key: "body", value: response});
                    }
                })                
            }, 250);
            setTimeout(() => { clearInterval(checker) }, 30*1000);
        }
    }, [url]);

    useEffect(() => {
        if (fullBody && harder.intent) {
            GetPrompt("scoring", harder.prompts.scoring).then(([prompt_func, parser_func]) => {
                setDcgPrompt(prompt_func(harder, {title, url, snippet, fullBody}));
            });
            GetPrompt("caption", harder.prompts.caption).then(([prompt_func, parser_func]) => {
                setCaptionPrompt(prompt_func(harder, {title, url, snippet, fullBody}));
            });
        }
    }, [fullBody, harder.intent, harder.language, harder.region])

    return (
    <tr>
        <td><a href={url} rel="noreferrer" target="_blank">{title}</a></td>
        <td>
        {!harder.disableGenCaptions && fullBody && harder.intent && <Completion name={`gencaption-${urlhash}`} prompt={captionPrompt} prefix="TITLE:" 
            onDone={g => {
                GetPrompt("caption", harder.prompts.caption).then(([prompt_func, parser_func]) => {
                    try {
                        const [gentitle, gensnippet] = parser_func(g);
                        setGencaptionTitle(gentitle);
                        setGencaptionSnippet(gensnippet);
                        dispatch({type: "setResultDetailNewOnly", url: url, key: "title", value: gentitle});
                        dispatch({type: "setResultDetailNewOnly", url: url, key: "snippet", value: gensnippet});
                        dispatch({type: "setResultDetailNewOnly", url: url, key: "gencap", value: true});
                    }
                    catch (ex) {
                        // failure to parse
                        appInsights.trackException({ exception: ex, customProperties: { rawResponse: g } })
                    }
                });
            }}
            onReceive={setGencaption}></Completion>}
        </td>
        <td>
        { fullBody && harder.intent && <Completion name={`dcg-${urlhash}`} prompt={dcgPrompt} onDone={j => {
            GetPrompt("scoring", harder.prompts.scoring).then(([prompt_func, parser_func]) => {
                try {
                    const final = parser_func(j)
                    if (final) {
                        setDcg(final);
                        dispatch({type: "setResultDetail", url: url, key: "dcg", value: final});
                    }
                } catch (ex) {
                    // failure to parse
                    appInsights.trackException({ exception: ex, customProperties: { rawResponse: j } })
                }
            });
        }}></Completion>}
        </td>
    </tr>

    );
}

export default SerpResult;



