import { useState } from 'react';
import Toggle from 'react-toggle'
import 'react-toggle/style.css'
import { useHarderState } from '../HarderProvider'

function Toggles() {
    const [harder, dispatch] = useHarderState();

    const languages = ["en", "zh"];
    const regions = ["US", "CN"];

    const [language, setLanguage] = useState(harder.language);
    const [region, setRegion] = useState(harder.region);

    const [anid, setAnid] = useState(harder.anid || "0000000000000000")
    const [wapi_days, setWapiDays] = useState(harder.wapi_days || 30)

    return (
        <table style={{ fontSize: 'initial' }}>
            <tbody>
                <tr>
                    <td>Google context</td>
                    <td><Toggle defaultChecked={!harder.disableGoogle} onChange={e => dispatch({ type: 'set', key: 'disableGoogle', value: !e.target.checked, persist: true })}></Toggle></td>
                </tr>
                <tr>
                    <td>Personalization</td>
                    <td><Toggle defaultChecked={!harder.disablePersonalization} onChange={e => dispatch({ type: 'set', key: 'disablePersonalization', value: !e.target.checked, persist: true })}></Toggle></td>
                </tr>
                <tr>
                    <td>Generative captions</td>
                    <td><Toggle defaultChecked={!harder.disableGenCaptions} onChange={e => dispatch({ type: 'set', key: 'disableGenCaptions', value: !e.target.checked, persist: true })}></Toggle></td>
                </tr>
                <tr>
                    <td>Qprimes</td>
                    <td><Toggle defaultChecked={!harder.disableQprimes} onChange={e => dispatch({ type: 'set', key: 'disableQprimes', value: !e.target.checked, persist: true })}></Toggle></td>
                </tr>
                <tr>
                    <td>Dupe penalty</td>
                    <td><Toggle defaultChecked={!harder.disableDupePenalty} onChange={e => dispatch({ type: 'set', key: 'disableDupePenalty', value: !e.target.checked, persist: true })}></Toggle></td>
                </tr>
                <tr>
                    <td>Results from</td>
                    <td>
                        <select onChange={e => dispatch({ type: 'set', key: 'resultsFrom', value: e.target.value, persist: true })}>{
                            ["API", "pbjson"].map(l => <option selected={l === harder.resultsFrom} key={l}>{l}</option>)
                        }</select>
                        {harder.resultsFrom === "pbjson" && <span style={{ fontSize: "smaller" }}>(corpnet only)</span>}
                    </td>
                </tr>
                <tr>
                    <td>
                        Market
                    </td>
                    <td>
                        <input text="language" style={{ width: "50px" }} list="languages" value={language} onChange={e => setLanguage(e.target.value)}></input>
                        <datalist id="languages">{languages.map(l => <option value={l} key={l}></option>)}</datalist>
                        <input text="region" style={{ width: "50px" }} list="regions" value={region} onChange={e => setRegion(e.target.value)}></input>
                        <datalist id="regions">{regions.map(l => <option value={l} key={l}></option>)}</datalist>
                        <button onClick={e => {
                            dispatch({ type: 'set', key: 'language', value: language, persist: true });
                            dispatch({ type: 'set', key: 'region', value: region, persist: true });
                        }}>Set</button>
                    </td>
                </tr>
                <tr>
                    <td>Use WAPI Batch API</td>
                    <td><Toggle defaultChecked={!harder.disableWapi} onChange={e => dispatch({ type: 'set', key: 'disableWapi', value: !e.target.checked, persist: true })}></Toggle></td>
                </tr>
                <tr>
                    <td>
                        ANID
                    </td>
                    <td>
                        <input text="anid" style={{ width: "100px" }} value={anid} onChange={e => setAnid(e.target.value)}></input>
                        <input text="days" style={{ width: "50px" }} value={wapi_days} onChange={e => setWapiDays(e.target.value)}></input>
                        <button onClick={e => {
                            dispatch({ type: 'set', key: 'anid', value: anid, persist: true });
                            dispatch({ type: 'set', key: 'wapi_days', value: wapi_days, persist: true });

                            window.location.reload()
                        }}>Set</button>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default Toggles