import { useState, useMemo } from 'react'
import { useHarderState } from './HarderProvider';

function Searchbox() {
    const [ harder, dispatch ] = useHarderState();

    let q_param = new URLSearchParams(window.location.search).get("q");
    if (q_param) {
        document.title = `${q_param} - Search Harder`
    }
    useMemo(() => dispatch({type: 'set', key: 'q', value: q_param}), [q_param]);
    const [ querytextbox, setQuerytextbox ] = useState(q_param);

    return (
        <input id="searchbox" type="text" placeholder="Search" value={querytextbox} onChange={e => setQuerytextbox(e.target.value)}
          onKeyUp={e => { if (e.key === 'Enter') { window.location = `/search?q=${encodeURIComponent(querytextbox)}` }}}></input>
    )
}

export default Searchbox;