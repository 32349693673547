import { DcgComputer } from "./DcgComputer";
import { SortResults } from "./ResultList";

import './ScoreCompare.css'

export default function ScoreCompare({left, leftField, leftReverse, right, rightField, rightReverse, penalizeDupes}) {
    let left_algos = left.filter(x => x[leftField]);
    left_algos.sort((a,b) => (leftReverse?-1:1) * (a[leftField] - b[leftField]))

//    let right_algos = right.filter(x => x[rightField]);
    //right_algos.sort((a,b) => (rightReverse?-1:1) * (a[rightField] - b[rightField]))
    let right_algos = SortResults(right, rightField, rightReverse, penalizeDupes);

    const [left_dcg3, left_dcg10] = DcgComputer(left_algos.map(r => r.dcg));
    const [right_dcg3, right_dcg10] = DcgComputer(right_algos.map(r => r.dcg));

    const signedDelta = (l, r, at) => {
        if (l == r) return;

        const col = (r>l) ? "green" : "red";
        return (<span style={{backgroundColor: col, color: 'white', padding: "0.5em"}}>
            {r>l ? "+" : "-"}{Math.abs(r-l).toFixed(0)}{at}
          </span>)
    };

    return (
        <div className="scorebox">
            <div>Original {left_dcg3.toFixed(0)}@3, {left_dcg10.toFixed(0)}@10</div>
            <div>
                {signedDelta(left_dcg3, right_dcg3, "@3")}
                {" "}
                {signedDelta(left_dcg10, right_dcg10, "@10")}
            </div>
            <div>Improved {right_dcg3.toFixed(0)}@3, {right_dcg10.toFixed(0)}@10</div>
        </div>
    )

}