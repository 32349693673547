export const msalConfig = {
    auth: {
        clientId: '8750ae5c-68a1-4fdb-b617-f5f3b885b35d',
        authority: "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
        redirectUri: window.location.protocol + "//" + window.location.host 
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
    }
};

export const loginRequest = {
    scopes: [
        "User.Read",
    ]
}

export const logoutRequest = {
    postLogoutRedirectUri: msalConfig.auth.redirectUri
}

export function getAccessToken({instance, accounts}) {
    return instance.acquireTokenSilent({
      scopes: ['8750ae5c-68a1-4fdb-b617-f5f3b885b35d/search'],
      account: accounts[0],
    })
}