/*global chrome*/
import { useEffect, useState } from "react";
import Completion from "../gpt/Completion";
import { useHarderState } from "../HarderProvider";
import Markdown from 'react-markdown'
import { GetPrompt } from "../prompts/inventory";

function SessionIntent() {
    const [harder, dispatch] = useHarderState();
    
    const [prompt, setPrompt] = useState(null);
    const [shorthistory, setShorthistory] = useState(null);

    const [sessionIntent, setSessionIntent] = useState(null);

    useEffect(() => {
        if (!chrome.runtime) return;
        chrome.runtime.sendMessage(process.env.REACT_APP_EXTENSION_ID, { type: 'getHistory', minutes: 30 }, (response) => { setShorthistory(response); });
    }, []);

    useEffect(() => {
        if (shorthistory && harder.location && harder.ltProfile) {
            GetPrompt("profile_recent", harder.prompts.profile_recent).then(([prompt_func, parser_func]) => {
                setPrompt(prompt_func(harder, shorthistory));
            });
        }
        //eslint-disable-next-line
    }, [shorthistory, harder.ltProfile, harder.prompts.profile_recent, harder.location])

    if (!shorthistory || !harder.location) return (<div>Loading short term...</div>);

    if (shorthistory.queries.length === 0 && shorthistory.titles.length === 0) {
        dispatch({type: "set", key: "sessionIntent", value: "No recent search or browse activity"})
    }

    return (
        <div>
            <h3>Session intent</h3>
            {shorthistory && <div>Recently
                {" "}
            {shorthistory.queries.length} searches, {shorthistory.titles.length} browse events
            </div>}

            {prompt && <Completion name="sessionintent" prompt={prompt} prefix="1." onReceive={setSessionIntent} onDone={v => dispatch({type: "set", key: "sessionIntent", value: v})}></Completion>}
            <Markdown>{harder.stIntent || sessionIntent}</Markdown>
        </div>
    )
}

export default SessionIntent;

