import { useHarderState } from "../HarderProvider";

function Progress() {
    const [harder, disable] = useHarderState();

    if (harder.stats.gpt_calls === 0) return null;
    if (harder.stats.gpt_calls === harder.stats.gpt_calls_completed) return null;

    return (<div style={{backgroundColor: 'aliceblue', padding: '0.5em'}}>
        Working {harder.stats.gpt_calls_completed} / {harder.stats.gpt_calls} GPT calls
        <progress style={{width: "100%"}} value={harder.stats.gpt_calls_completed} max={harder.stats.gpt_calls}></progress>
        </div>)
}

export default Progress;