import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import FastStart from './FastStart';
import reportWebVitals from './reportWebVitals';

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest, msalConfig } from "./authConfig";

import { HarderProvider } from './HarderProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Pbxml from './debug/Pbxml';
import WhoAmI from './debug/WhoAmI';

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <UnauthenticatedTemplate>
        <button onClick={() => msalInstance.loginRedirect(loginRequest)}>Sign in</button>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate> 
        <HarderProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" Component={App} /> 
              <Route path="/search" Component={App} /> 
              <Route path="/faststart" Component={FastStart} />
              <Route path="/debug/pbxml" Component={Pbxml} />
              <Route path="/debug/whoami" Component={WhoAmI} />
            </Routes>
          </BrowserRouter>
        </HarderProvider>
      </AuthenticatedTemplate>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
