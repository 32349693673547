/* global chrome */
import { useEffect } from "react";
import SerpResult from "./SerpResult";
import { useHarderState } from "../HarderProvider";
import { appInsights } from "../appInsights";
import { getAccessToken } from "../authConfig";
import { useMsal } from "@azure/msal-react";

export const hashCode = s => s.split('').reduce((a,b) => (((a << 5) - a) + b.charCodeAt(0))|0, 0)
const fixHighlights = s => s.replace(//g, "**").replace(//g, "**")


function SerpList() {
    const [harder, dispatch] = useHarderState();
    const { instance, accounts } = useMsal();

    useEffect(() => {
        let surpassed = false;

        if (harder.resultsFrom === "API") {
            appInsights.trackEvent({name: "Bing API call"})
            const helpers = process.env.REACT_APP_HELPERS_URL ||  (window.location.protocol + "//" + window.location.host + "/helpers");
            getAccessToken({instance, accounts})
            .then(token => {
                fetch(
                    `${helpers}/bingserp?q=${encodeURIComponent(harder.q)}&mkt=${harder.language}-${harder.region}&clientid=${harder.clientid}`,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token.accessToken}` 
                        }
                    }
                )
                .then(r => r.json())
                .then(r => {
                    if (!surpassed) {
                        for (let i=0; i<r.algos.length; i++) {
                            r.algos[i].original = i+1;
                            r.algos[i].random = Math.random()*100.0
                            r.algos[i].urlhash = hashCode(r.algos[i].url)
                        }
                        dispatch({type: "setResults", results: r.algos})
                        dispatch({type: "set", key: "clientid", value: r.clientid, persist: true})

                        appInsights.trackEvent({
                            name: "Bing API results received", 
                            properties: {
                                q: harder.q,
                                mkt: harder.language + "-" + harder.region,
                                ig: r.ig,
                                count: r.algos.length
                            }})
                    }
                }) 
            })
        }

        if (harder.resultsFrom === "pbjson") {
            appInsights.trackEvent({name: "Bing pbjson call"})
            const aug = `[urp:option:Main|XMerger|CutoffCount|200][urp:option:Main|MergerPostprocess|KeepAllDocs|true]`
            const url = `https://www.bing-exp.com/search?q=${harder.q}&&useraugmentation=${encodeURIComponent(aug)}&mkt=${harder.language}-${harder.region}&corpredir=1&wf=QueryProbe.bingfirstpageresults&format=pbjson`;
            chrome.runtime.sendMessage(process.env.REACT_APP_EXTENSION_ID, { type: 'startFetchRaw', url: url });
            const checker = setInterval(() => {
                chrome.runtime.sendMessage(process.env.REACT_APP_EXTENSION_ID, { type: 'checkFetch', url: url}, response => {
                    if (response) {
                        clearInterval(checker);
                        const pbjson = JSON.parse(response);
                        const webans = pbjson.PropertyBag.AnswerResponseCommand.AnswerQueryResponse.AnswerDataArray.filter(x => x.AnswerServiceName === "WebAnswer")[0];
                        let results = [];
                        let i=1;
                        for (let algo of webans.AnswerDataKifResponse[0].results) {
                            results.push({
                                title: fixHighlights(algo.Title),
                                url: algo.Url,
                                snippet: fixHighlights(algo.Snippet),
                                original: i++,
                                urlhash: hashCode(algo.Url)
                            })
                        }
                        dispatch({type: "setResults", results: results});
                    }
                })                
            }, 250);
        }



        return () => surpassed = true;
    }, [harder.q]);

    return (
        <div>
            {harder.results.map((result, i) => <SerpResult {...result} key={result.url}></SerpResult>)}          
        </div>
    )
}


export default SerpList