import { useHarderState } from "./HarderProvider";
import { useMemo, useState } from "react";

export function Stats() {
    const [ harder , ] = useHarderState();

    const [earliest, setEarliest] = useState(null);
    const [latest, setLatest] = useState(null);

    useMemo(() => {
        let t1 = null;
        let t2 = null;

        for (let k of Object.keys(harder.stats.timers)) {
            const timer = harder.stats.timers[k];
            if (!t1 || timer.start < t1) t1 = timer.start;
            if (!t2 || timer.end > t2) t2 = timer.end;
        }

        setEarliest(t1);
        setLatest(t2);
    }, [harder.stats.timers]);

    return (<div>
        <div>clientid: {harder.clientid}</div>
        <div>gpt_calls: {harder.stats.gpt_calls}</div>
        <div>context chars: {harder.stats.gpt_chars_context}</div>
        <div>generated: { harder.stats.gpt_tokens_completion}</div>
        <div>timings: 
            {Object.keys(harder.stats.timers).map(k => {
                const timer = harder.stats.timers[k];
                return <tr><td style={{paddingLeft: "1em"}}>{k}</td><td>{timer.end ? (timer.end - timer.start).toLocaleString("en") : "running"}</td></tr>
            })}
        </div>
        {earliest && latest && <div>total gpt time: {(latest - earliest).toLocaleString("en")}</div>}
    </div>)
}