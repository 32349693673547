import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory({ basename: '' });
const appInsightsPlugin = new ReactPlugin();
var clickPluginInstance = new ClickAnalyticsPlugin();
   var clickPluginConfig = {
     autoCapture: true
}; 

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY,
    extensions: [appInsightsPlugin, clickPluginInstance],
    extensionConfig: {
      [appInsightsPlugin.identifier]: { history: browserHistory },
      [clickPluginInstance.identifier]: clickPluginConfig    
    },
  },
});
appInsights.loadAppInsights();
export { appInsightsPlugin, appInsights };