export const inventory = {
    qprimes: [
        "default",
        "aoakley-0001",
        "aoakley-0002",
        "jiaswan-0001",
    ],

    intent: [
        "default",
        "aoakley-0001",
        "aoakley-0002",
        "ponedcg-0033",
        "aoakley-narrow",
        "aoakley-broad",
        "aoakley-representative",
        "ranganm-0001"
    ],

    profile_long: [
        "default",
        "aoakley-identity",
        "ponedcg-0033"
    ],

    profile_recent: [
        "default",
        "ponedcg-0033"
    ],

    scoring: [
        "default"
    ],

    caption: [
        "default"
    ]
}


export async function GetPrompt(scenario, version) {
    const mod = await import(`./${scenario}/${version}.js`)
    return [mod.prompt, mod.parser]
}