import { useEffect, useState } from "react";
import { useHarderState } from "../HarderProvider";
import { appInsights } from "../appInsights";
import { getAccessToken } from "../authConfig";
import { useMsal } from "@azure/msal-react";

function mergeDatedHistory(raw) {
    // Sort raw by the Date property
    raw.sort((a, b) => {
        return new Date(b.Date) - new Date(a.Date);
    });
    const merged = raw.reduce((acc, obj) => {
        for (let key in obj) {
            if (acc[key]) {
                if (Array.isArray(acc[key])) {
                    acc[key] = acc[key].concat(obj[key]);
                }
            } else {
                acc[key] = obj[key];
            }
        }
        return acc;
    }, {});

    return merged;
}

function WapiHistory() {
    const [harder, dispatch] = useHarderState();
    const [history, setHistory] = useState(null);

    const { instance, accounts } = useMsal();

    useEffect(() => {
        if (!harder.disableWapi) {
            appInsights.trackEvent({ name: "WAPI API call" })
            const helpers = process.env.REACT_APP_HELPERS_URL || (window.location.protocol + "//" + window.location.host + "/helpers");
            if (harder.anid) {
                getAccessToken({ instance, accounts })
                    .then(token => {
                        fetch(
                            `${helpers}/wapi_anid?anid=${encodeURIComponent(harder.anid)}&wapi_days=${encodeURIComponent(harder.wapi_days)}`,
                            {
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${token.accessToken}`
                                }
                            })
                            .then(r => r.json())
                            .then(r => {
                                if (r && r.length > 0) {
                                    let merged_history = mergeDatedHistory(r);
                                    // Check if BingSearchEvents and BrowserEvents are in merged history
                                    if (!merged_history.BingSearchEvents) {
                                        merged_history.BingSearchEvents = [];
                                    }
                                    if (!merged_history.BrowserEvents) {
                                        merged_history.BrowserEvents = [];
                                    }
                                    setHistory(merged_history);
                                    dispatch({ type: "setWapiHistory", value: merged_history })
                                    appInsights.trackEvent({
                                        name: "WAPI history received",
                                        properties: {
                                            anid: harder.anid,
                                            count: r.length
                                        }
                                    })
                                } else {
                                    appInsights.trackEvent({
                                        name: "WAPI history error",
                                        properties: {
                                            anid: harder.anid,
                                        }
                                    })
                                    alert("History empty or not available in WAPI");
                                }
                            })
                    })
            }
        }
        return;
    }, [harder.anid, harder.disableWapi]);

    return (
        <div>
            <div>WAPI Search History Logs - {harder.anid}</div>
            <div style={{ height: "200px", "overflow-y": "scroll" }}>
                {((history && history.BingSearchEvents && history.BingSearchEvents.length > 0) ? history.BingSearchEvents.map((result, i) => {
                    return (
                        <table>
                            <tbody>
                                <tr key={i}>
                                    <td>
                                        {result.Browser}
                                    </td>
                                    <td>
                                        {result.QueryTime}
                                    </td>
                                    <td>
                                        {result.Query}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    )
                }): (<div>No Search Events from WAPI</div>))}
            </div>
            <div>WAPI Browser Logs - {harder.anid}</div>
            <div style={{ height: "200px", "overflow-y": "scroll" }}>
                {((history && history.BrowserEvents && history.BrowserEvents.length > 0) ? history.BrowserEvents.map((result, i) => {
                    return (
                        <table>
                            <tbody>
                                <tr key={i}>
                                    <td>
                                        {result.EventTime}
                                    </td>
                                    <td>
                                        {result.PageTitle}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    )
                }) : (<div>No Browser Events from WAPI</div>))}
            </div>
        </div>
    )
}


export default WapiHistory;