export function DcgComputer(scores) {
    const depth = 10;
    const interesting = [3, 5, 10];

    var posarr = []; for (let i=1; i<=depth; i++) { posarr.push(i); }

    var maxdg = [];
    for (let i=0; i<depth; i++) {
        maxdg.push((0.6**i) * 100);
    }
    var maxdcg = [];
    for (let i=0; i<depth; i++) {
        let acc = 0;
        for (let j=0; j<=i; j++) { acc += maxdg[j]; }
        maxdcg.push(acc);
    }

    var dcg = {};

    for (let i=0; i<depth; i++) {
        let acc = 0;
        for (let j=0; j<=i; j++) {
            acc += (scores[j] ?? 0) * (0.6**j)
        }
        dcg[i] = acc / maxdcg[i] * 100;
    }    

    return [dcg[3-1], dcg[10-1]];
}
