/* global chrome */
import { useState } from "react";

function Pbxml() {
    const [url, setUrl] = useState("");
    const [response, setResponse] = useState(null);

    return (
        <div>
            <h1>Debug Pbxml</h1>
            <input value={url} onChange={e => setUrl(e.target.value)}></input>
            <button onClick={() => {
                chrome.runtime.sendMessage(process.env.REACT_APP_EXTENSION_ID, { type: 'startFetchRaw', url: url });
                const checker = setInterval(() => {
                    chrome.runtime.sendMessage(process.env.REACT_APP_EXTENSION_ID, { type: 'checkFetch', url: url}, response => {
                        if (response) {
                            clearInterval(checker);
                            const pbjson = JSON.parse(response);
                            //const xml = new window.DOMParser().parseFromString(response, "text/xml");
                            const webans = pbjson.PropertyBag.AnswerResponseCommand.AnswerQueryResponse.AnswerDataArray.filter(x => x.AnswerServiceName === "WebAnswer")[0];
                            console.log(webans);
                            setResponse(webans.AnswerDataKifResponse[0].results);
                        }
                    })                
                }, 250);
            setTimeout(() => { clearInterval(checker) }, 30*1000);
            }}>Fetch</button>

            {response && <>
                <div>{response.length} results</div>
                <div>
                    {response.map(r => <p>
                        <div>{r.Title}</div>
                        <div>{r.Url}</div>
                        <div>{r.Snippet}</div>
                        </p>)}
                </div>
                </>}  
        </div>
    );
}

export default Pbxml;