/* global chrome */
import { useEffect, useState } from "react";
import { useHarderState } from "../HarderProvider";
import { getAccessToken } from "../authConfig";
import { useMsal } from "@azure/msal-react";

function UserLocation() {
    const [ harder, dispatch ] = useHarderState();
    const [ locationDisallowed, setLocationDisallowed ] = useState(false);

    const [ detectedLocation, setDetectedLocation ] = useState(null);

    const [ override, setOverride ] = useState(localStorage.getItem("overrideLocation") !== null);
    const [ openOverride, setOpenOverride ] = useState(override);
    const [ overrideLocation, setOverrideLocation] = useState(localStorage.getItem("overrideLocation"));

    const { instance, accounts } = useMsal();

    useEffect(() => {
        if (!chrome.runtime) return;
        chrome.runtime.sendMessage(process.env.REACT_APP_EXTENSION_ID, { type: 'getLocation' }, (pos) => { 
            if (pos.coords) {
                getAccessToken({instance, accounts})
                .then(token => {
                    const helpers = process.env.REACT_APP_HELPERS_URL ||  (window.location.protocol + "//" + window.location.host + "/helpers");
                    fetch(
                        `${helpers}/rgeocode?coords=${pos.coords.latitude},${pos.coords.longitude}`,
                        {
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token.accessToken}` 
                            }
                        }
                    )
                    .then(r => r.json())
                    .then(r => {
                        try {
                            setDetectedLocation(r.resourceSets[0].resources[0].name);
                            if (!override) {
                                dispatch({type: 'set', key: 'location', value: r.resourceSets[0].resources[0].name});
                            }
                        }
                        catch {
                            setDetectedLocation("Unknown location")
                            if (!override) {
                                dispatch({type: 'set', key: 'location', value: `Unknown location`});
                            }
                            setLocationDisallowed(true);
                        }
                    })
                    .catch(err => console.log(err))
                })
            }
            else {
                setDetectedLocation("Unknown location")
                if (!override) {
                    dispatch({type: 'set', key: 'location', value: `Unknown location`});
                }
                setLocationDisallowed(true);
            }
        });

        if (override) {
            dispatch({type: 'set', key: 'location', value: overrideLocation});
        }
    }, [])

    return (<div>
        <h3>User location</h3>
        {locationDisallowed && <div>Location Services are disabled</div>}
        
        <div style={{textDecoration: override ? "line-through" : "none"}}>{detectedLocation}</div>

        <div>
            {!openOverride && <button onClick={() => setOpenOverride(true)}>Override</button>}
            {openOverride && <>
                <input value={overrideLocation} onChange={e => setOverrideLocation(e.target.value)}></input>
                <button onClick={() => {
                    dispatch({type: 'set', key: 'location', value: overrideLocation})
                    setOverride(true);
                    localStorage.setItem("overrideLocation", overrideLocation);
                }}>Set</button>
                <button onClick={() => { 
                    setOverride(false);
                    setOpenOverride(false);
                    localStorage.removeItem("overrideLocation");
                    dispatch({type: 'set', key: 'location', value: detectedLocation});
                }}>Clear</button>
                </>}
        </div>
    </div>)

}

export default UserLocation;