/* global chrome */
import React, { useState, useEffect } from 'react';

function ExtensionVersion() {
    const [version, setVersion] = useState(null);

    useEffect(() => {
        if (chrome && chrome.runtime) {
            chrome.runtime.sendMessage(process.env.REACT_APP_EXTENSION_ID, { type: 'getVersion' }, (response) => { setVersion(response); });
        }
    }, [])

    if (version) {
        return `Extension ${version.version} ${version.version_name}`;
    }
}

export default ExtensionVersion