/*global chrome*/
import { useEffect, useState } from "react";
import Completion from "../gpt/Completion";
import { useHarderState } from "../HarderProvider";
import Markdown from 'react-markdown'
import { GetPrompt } from "../prompts/inventory";

function LongTermProfile() {
    const [harder, dispatch] = useHarderState();
    
    const [prompt, setPrompt] = useState(null);
    // fullhistory assumes the same schema as WAPI history
    // It has two main fields: BingSearchEvents and BrowserEvents, each is a list of dict.
    const [fullhistory, setFullhistory] = useState(null);

    const [persona, setPersona] = useState(null);
    const [loadingHistory, setLoadingHistory] = useState(true);


    useEffect(() => {
        if (!chrome.runtime) return;
        
        if (!harder.ltProfile) {    
            setLoadingHistory(true);
            if (harder.disableWapi) {
                chrome.runtime.sendMessage(process.env.REACT_APP_EXTENSION_ID, { type: 'getHistory', minutes: 60*24*7 }, (response) => { 
                    // response has two field queries (each has dt and query), and titles (each has dt and title)
                    let search = [];
                    let browse = [];
                    for (let query of response.queries) {
                        search.push({
                            Query: query.query,
                            QueryTime: query.dt
                        });
                    }

                    for (let title of response.titles) {
                        browse.push({
                            PageTitle: title.title,
                            EventTime: title.dt
                        });
                    }

                    setFullhistory({
                        BingSearchEvents: search,
                        BrowserEvents: browse
                    }); 
                });
                setLoadingHistory(false);
            } else {
                if (harder.wapi_history) {
                    setFullhistory(harder.wapi_history);
                    setLoadingHistory(false);
                }
            }
            return;
        }
    }, [harder.ltProfile, harder.disableWapi, harder.wapi_history]);

    useEffect(() => {
        if (fullhistory && harder.location) {
            console.log(fullhistory);
            GetPrompt("profile_long", harder.prompts.profile_long).then(([prompt_func, parser_func]) => {
                setPrompt(prompt_func(harder, fullhistory));
            });
        }
    }, [harder.ltProfile, harder.prompts.profile_long, fullhistory, harder.location])

    return (
        <div>
            <h3>Long term profile</h3>

            {loadingHistory && <div>Loading history...</div>}

            {fullhistory && <div>
                {fullhistory.BingSearchEvents? fullhistory.BingSearchEvents.length : 0 } searches, {fullhistory.BrowserEvents?fullhistory.BrowserEvents.length:0} browse events
            </div>}

            {!harder.ltProfile && prompt && <Completion name="longterm" prompt={prompt} onReceive={setPersona} onDone={v => dispatch({type: "set", key: "ltProfile", value: v, persist: true})}></Completion>}
            <Markdown>{harder.ltProfile || persona}</Markdown>

            {harder.ltProfile && <button onClick={() => {
                dispatch({type: "set", key: "ltProfile", value: null, persist: true});
            } }>Regenerate</button>}

        </div>
    )
}

export default LongTermProfile;
