/*global chrome*/
import { useEffect, useState } from "react";
import { useHarderState } from "../HarderProvider";

const topk = 30;

function SearchHistory() {
    const [history, setHistory] = useState([]);
    const [harder, dispatch] = useHarderState();

    useEffect(() => {
        if (!harder.wapi_history) return;
        setHistory(harder.wapi_history.BingSearchEvents);
    }, [harder.wapi_history, harder.anid, harder.disableWapi]);

    return !harder.disableWapi && history && (
        <div>
            <h3>Wapi History Top {topk} [ANID: {harder.anid}]</h3>
            {harder.wapi_history &&
                <div style={{ height: "200px", "overflow-y": "scroll" }}>

                    <table>
                        <thead>
                            <tr>
                                <th>Query</th>
                                <th>Timestamp</th>
                            </tr>
                        </thead>
                        <tbody>
                            {history.slice(0, topk).map((item) => (
                                <tr key={`${item.QueryTime}${item.Query}`}>
                                    <td>{item.Query}</td>
                                    <td>{new Date(item.QueryTime).toLocaleString()}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            }
        </div>
    );
}

export default SearchHistory;
