import './FastStart.css'
import './App.css'
import Searchbox from './Searchbox';
import ExtensionCheck from './ExtensionCheck';
import Markdown from 'react-markdown';
import { useState, useEffect } from 'react';
import SerpList from './results/SerpList';
import { useHarderState } from './HarderProvider';
import QueryPrimes from './meta/QueryPrime';
import ResultList from './results/ResultList';
import Progress from './meta/Progress';
import GoogleResults from './results/GoogleResults';
import SessionIntent from './meta/SessionIntent';
import LongTermProfile from './meta/LongTermProfile';

export default function FastStart() {
    const [harder, dispatch] = useHarderState();

    useEffect(() => {
        const usp = new URLSearchParams(window.location.search);
        dispatch({type: 'set', key: 'q', value: usp.get('q')})
        dispatch({type: 'set', key: 'intent', value: usp.get('mg')})
    }, window.location.search)

    const filtered_results = harder.results.filter(res => (res.dcg && res.dcg>50));
    
    return (
        <div className="App">
        <div className="header">
            Search Harder
            <Searchbox></Searchbox>
            <div>
            Questions to <a href="mailto:aoakley@microsoft.com">aoakley</a>.
            </div>
        </div>
        <ExtensionCheck></ExtensionCheck>
          

        <main>
          {filtered_results.length === 0 && 
            <div>
                <p>Most results are available within 15-20 seconds. </p>
                <p>Results may continue to update as better results are found.</p>
                <div className="waiting">🔍</div>
            </div>}
          {harder.q && <ResultList results={filtered_results} prefix="new" sortField="dcg" reverse={true} penalizeDupes={!harder.disableDupePenalty}></ResultList>}
        </main>

        <aside>
            <div className="richrswrapper rightrailmg">
                <p style={{margin:"0em"}}><Markdown>{harder.intent}</Markdown></p>
                <QueryPrimes></QueryPrimes>
            </div>
            <Progress></Progress>
        </aside>

        {harder.q && <div style={{display:"none"}}>
            <SerpList></SerpList>
            {!harder.disableGoogle && <GoogleResults></GoogleResults>}
            {!harder.disablePersonalization && <>
                <SessionIntent></SessionIntent>
                <LongTermProfile></LongTermProfile>
            </>}
        </div>}

        </div>

      )
}