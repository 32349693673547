import './SerpResult.css'
import Markdown from 'react-markdown'

function percentageToHsl(percentage, hue0, hue1) {
    var hue = (percentage * (hue1 - hue0)) + hue0;
    return 'hsl(' + hue + ', 100%, 50%)';
}

function Result({url, title, snippet, original, prefix, dcg, urlhash, body, gencap, qprime, dupe_multiplier}) {

    const filter = (dcg || dcg===0) ? `opacity(${0.1 + parseFloat(dcg)/100*0.9})` : "";
    const color = (dcg || dcg===0) ? percentageToHsl(parseFloat(dcg)/100.0, 0, 120) : null;

    return (
        <div className="resultcontainer">
            <div className="serpresult" style={{filter: filter}} id={prefix + urlhash}>
                <div className="t"><a href={url}><Markdown>{title}</Markdown></a></div>
                <div className="u"><a href={url}>{url}</a></div>
                <div className="s"><Markdown>{snippet}</Markdown></div>
            </div>
            <div className="resultstats">
                {original && <div>O#{original}</div>}
                {qprime && <div title={qprime}>Q'</div>}
                {(dcg || dcg===0) && <div style={{backgroundColor: color}}>{dcg}</div>}
                {dupe_multiplier && <div title={dupe_multiplier}>Dupish</div>}
                {body && <div>Body</div>}
                {gencap && <div>GenCap</div>}
            </div>
        </div>

    );
}

export default Result;
