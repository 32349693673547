import {useEffect, useState}  from "react";
import { useMsal } from "@azure/msal-react";

import { useHarderState } from "../HarderProvider";
import { not_more_than } from "../PromptHelper";

import { appInsights } from "../appInsights";

function Completion({prompt, prefix, name, parser, onReceive, onDone}) {
    const [ , setResponse ] = useState("");
    const { instance, accounts } = useMsal();
    const [ , dispatch ] = useHarderState();

//    return (<div>Disabled</div>)

    useEffect(() => {
        if (!prompt) { return; }
        let surpassed = false;

        dispatch({type: "logGptCall"})
        appInsights.trackEvent({name: "GPT Call"})

        const onSockReceive = (msg) => {
            if (surpassed) { return; }

            const data = JSON.parse(msg.data);

            if (data.type === 'sse') {
                var fragment = data.payload.choices[0].text;
                dispatch({type: "logGptCompletion"})
                setResponse(prevState => {
                    const newState = (prevState.length === 0 ? (prefix ?? "") : prevState) + fragment;
                    if (onReceive) { onReceive(newState); }
                    return newState
                });
            }

            if (data.type === "status" && data.details === "Done") {
                setResponse(prevState => {
                    if (onDone) { onDone(prevState); }
                    return prevState;
                })

                if (name) { dispatch({type: "stopTimer", name: name}) }

                sock.close();
            }
        }

        const proxy = process.env.REACT_APP_PAPYRUS_URL ||  ("wss://" + window.location.host + "/papyrus/");
        var sock = new WebSocket(proxy);

        sock.onopen = () => {
            instance.acquireTokenSilent({
                scopes: ['8750ae5c-68a1-4fdb-b617-f5f3b885b35d/search'],
                account: accounts[0],
            })
            .then(r => {
                const request = {
                    prompt: prompt,
                    temperature: 0.0,
                    max_tokens: 500,
                    top_p: 1.0
                }

                const msg = {
                    endpoint: "gpt4o",
                    auth: r.accessToken,
                    payload: request
                }

                dispatch({type: "logGptContext", count: prompt.length})
                if (name) {
                    dispatch({type: "startTimer", name: name})
                }

                setResponse("");
                sock.send(JSON.stringify(msg));
            })
        };
        
        sock.onmessage = onSockReceive;
        sock.onclose = (e) => { dispatch({type: "logGptDone"}) }

        return () => {
            sock.close();
            surpassed = true;
        }
    }, [prompt]);


    return (<a style={{textDecoration: "none", float: 'right'}} target="_blank" rel="noreferrer" href={not_more_than(`https://codexplayground.binginternal.com/?prompt=${encodeURIComponent(prompt)}`, 24000)}>
        📜
    </a>);
}


export default Completion;