import { useContext, useReducer, createContext } from 'react';

const HarderContext = createContext(null);
const HarderDispatchContext = createContext(null);

export function HarderProvider({ children }) {
  const [harder, dispatch] = useReducer(harderReducer, initialState);

  return (
    <HarderContext.Provider value={harder}>
      <HarderDispatchContext.Provider value={dispatch}>
        {children}
      </HarderDispatchContext.Provider>
    </HarderContext.Provider>
  );
}

function harderReducer(prevState, action) {
  switch (action.type) {
    case 'setResults': {
      return {
        ...prevState,
        results: action.results,
        original_results: action.results
      }
    }
    case 'addResult': {
      // already there, nothing to update
      if (prevState.results.filter(x => x.url === action.result.url).length > 0) { return prevState; }
      return {
        ...prevState,
        results: [...prevState?.results, action.result]
      }
    }
    case 'setResultDetailNewOnly': {
      return {
        ...prevState,
        results: prevState.results.map(r => {
          if (r && r.url === action.url) {
            return {...r, [action.key]: action.value}
          }
          else return r;
        })
      }
    }
    case 'setResultDetail': {
      return {
        ...prevState,
        results: prevState.results.map(r => {
          if (r && r.url === action.url) {
            return {...r, [action.key]: action.value}
          }
          else return r;
        }),
        original_results: prevState.original_results.map(r => {
          if (r && r.url === action.url) {
            return {...r, [action.key]: action.value}
          }
          else return r;
        })
      }
    }

    case 'clearScores': {
      return {
        ...prevState,
        original_results: prevState.original_results.map(r => {
          return {...r, dcg: null}
        }),
        results: prevState.results.map(r => {
          return {...r, dcg: null}
        })
      }
    }
    case 'set': {
      if (action.persist) {
        if (action.value) { localStorage.setItem(action.key, action.value); }
        else { localStorage.removeItem(action.key); }
      }
      return {
        ...prevState,
        [action.key]: action.value
      }
    }

    case 'setPrompt': {
      localStorage.setItem("prompts." + action.key, action.value);
      return {
        ...prevState,
        prompts: {
          ...prevState.prompts,
          [action.key]: action.value
        }
      }
    }

    case 'setWapiHistory': {
      localStorage.setItem("wapi_history_" + action.anid, action.value);
      return {
        ...prevState,
        wapi_history: action.value
      }
    }

    case 'logGptCall': {
      return {
        ...prevState,
        stats: {
          ...prevState.stats,
          gpt_calls: prevState.stats.gpt_calls + 1
        }
      }
    }
    case 'logGptContext': {
      return {
        ...prevState,
        stats: {
          ...prevState.stats,
          gpt_chars_context: prevState.stats.gpt_chars_context + action.count
        }
      }
    }
    case 'logGptCompletion': {
      return {
        ...prevState,
        stats: {
          ...prevState.stats,
          gpt_tokens_completion: prevState.stats.gpt_tokens_completion + 1
        }
      }
    }
    case 'logGptDone': {
      return {
        ...prevState,
        stats: {
          ...prevState.stats,
          gpt_calls_completed: prevState.stats.gpt_calls_completed + 1
       }
      }
    }

    case 'startTimer': {
      return {
        ...prevState,
        stats: {
          ...prevState.stats,
          timers: {
            ...prevState.stats.timers,
            [action.name]: { start: new Date() }
          }
        }
      }
    }

    case 'stopTimer': {
      const timer = prevState.stats.timers[action.name];
      if (!timer) { return prevState; }
      return {
        ...prevState,
        stats: {
          ...prevState.stats,
          timers: {
            ...prevState.stats.timers,
            [action.name]: { start: timer.start, end: new Date() }
          }
        }
      }
    }
    

  case 'logFeature': {
    return {
      ...prevState,
      stats: {
        ...prevState.stats,
        features: prevState.stats.features.add(action.feature)
    }
  }
}

    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
  /*
  switch (action.type) {
    case 'added': {
      return [...tasks, {
        id: action.id,
        text: action.text,
        done: false
      }];
    }
    case 'changed': {
      return tasks.map(t => {
        if (t.id === action.task.id) {
          return action.task;
        } else {
          return t;
        }
      });
    }
    case 'deleted': {
      return tasks.filter(t => t.id !== action.id);
    }
  }
  */
}

// rewrite poor choice of defaults
const p_qprimes = localStorage.getItem("prompts.qprimes");
if (p_qprimes && p_qprimes === "aoakley-0001") { localStorage.setItem("prompts.qprimes", "default") }
const p_intent = localStorage.getItem("prompts.intent");
if (p_intent && p_intent === "aoakley-0001") { localStorage.setItem("prompts.intent", "default") }


const initialState = {
  q: null,
  location: null,
  language: localStorage.getItem("language") || "en",
  region: localStorage.getItem("region") || "US",
  dt: new Date(),
  intent: null,
  sessionIntent: null,
  results: [],
  original_results: [],
  google_results: null,
  anid: localStorage.getItem("anid"),
  wapi_days: localStorage.getItem("wapi_days") || 30,
  wapi_history: localStorage.getItem(`wapi_history_${localStorage.getItem("anid")}_${localStorage.getItem("wapi_days")}`),
  ltProfile: localStorage.getItem("ltProfile"),
  ltIdentity: localStorage.getItem("ltIdentity"),
  disablePersonalization: localStorage.getItem("disablePersonalization") === null || localStorage.getItem("disablePersonalization") === "true",
  disableGoogle: localStorage.getItem("disableGoogle") === "true",
  disableGenCaptions: localStorage.getItem("disableGenCaptions") === null || localStorage.getItem("disableGenCaptions") === "true",
  disableQprimes: localStorage.getItem("disableQprimes") === "true",
  disableDupePenalty: localStorage.getItem("disableDupePenalty") === "true",
  disableWapi: localStorage.getItem("disableWapi") === null || localStorage.getItem("disableWapi") === "true",
  resultsFrom: localStorage.getItem("resultsFrom") || "API",
  clientid: localStorage.getItem("clientid") || "",
  stats: {
    gpt_calls: 0,
    gpt_calls_completed: 0,
    gpt_chars_context: 0,
    gpt_tokens_completion: 0,
    features: new Set(),
    timers: {}
  },
  prompts: {
    qprimes: localStorage.getItem("prompts.qprimes") || 'default',
    intent: localStorage.getItem("prompts.intent") || 'default',
    profile_long: localStorage.getItem("prompts.profile_long") || 'default',
    profile_recent: localStorage.getItem("prompts.profile_recent") || 'default',
    scoring: localStorage.getItem("prompts.scoring") || 'default',
    caption: localStorage.getItem("prompts.caption") || 'default',
  }
}


export function useHarderState() {
  return [
    useContext(HarderContext),
    useContext(HarderDispatchContext)
  ]
}