/* global chrome */
import React, { useState, useEffect } from 'react';
import './ExtensionCheck.css'
import { useHarderState } from './HarderProvider';

function ExtensionCheck() {
    const [version, setVersion] = useState(null);
    const [grace, setGrace] = useState(true);
    const [harder, dispatch] = useHarderState();

    useEffect(() => {
        if (chrome && chrome.runtime) {
            chrome.runtime.sendMessage(process.env.REACT_APP_EXTENSION_ID, { type: 'getVersion' }, (response) => { 
                setVersion(response); 
            });
            setTimeout(() => setGrace(false), 1000);
        }
    }, [])

    useEffect(() => dispatch({type: "set", key: "extensionVersion", value: version}), [version]);
    if (version) {
        return 
    }

    if (window.location.protocol !== "https:") {
        console.log("Not served over SSL")
        return (
            <div className="extension_error">
                Not served over SSL. Cannot communicate with extensions.
            </div>
        );
    }

    if (chrome.runtime === undefined) {
        console.log("chrome.runtime undefined")
        return (
            <div className="extension_error">
            Cannot communicate with any extensions. Make sure <a href={process.env.REACT_APP_EXTENSION_STORE_URL}>Search Harder extension</a> is installed.
            </div>
        );
    }

    if (chrome === undefined) {
        console.log("chrome undefined")
        return (
             <div className="extension_error">
            Chrome missing. All bets are off.
            </div>
        )
    };

    if (grace) {
        console.log("Cannot communicate with extension but still in grace period")
        return;
    }

    //console.log("Cannot communicate with extension")
    //dispatch({type: "set", key: "extensionVersion", value: null});
    return (
        <div className="extension_error">
        Cannot communicate with Search Harder extension. Make sure <a href={process.env.REACT_APP_EXTENSION_STORE_URL}>Search Harder extension</a> is installed.
        </div>
    );
}

export default ExtensionCheck