import { useEffect, useState } from "react"
import { useHarderState } from "../HarderProvider";
import { appInsights } from "../appInsights";
import { getAccessToken } from "../authConfig";
import { useMsal } from "@azure/msal-react";

function GoogleResults() {
    const [ harder, dispatch ] = useHarderState();
    const [ fetching, setFetching ] = useState(false);

    const { instance, accounts } = useMsal();

    useEffect(() => {
        if (!harder.q) return;

        setFetching(true);

        appInsights.trackEvent({name: 'Google results request'})

        getAccessToken({instance, accounts})
        .then(token => {
            const helpers = process.env.REACT_APP_HELPERS_URL ||  (window.location.protocol + "//" + window.location.host + "/helpers");
            fetch(
                `${helpers}/googleserp?q=${encodeURIComponent(harder.q)}&mkt=${harder.language}-${harder.region}`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token.accessToken}` 
                    }
                }
            )
            .then(r => r.json())
            .then(r => {
                dispatch({type: 'set', key: 'google_results', value: r.prompt})
                setFetching(false);
            })
            .catch(err => console.log(err));
        })
    }, [harder.q]);

    return (<>
        {fetching && <div>Fetching Google context...</div>}
    </>)
}

export default GoogleResults