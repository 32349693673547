import './App.css';
import { useEffect } from 'react';
import SerpList from './results/SerpList';
import { useMsal } from "@azure/msal-react";
import ExtensionCheck from './ExtensionCheck';
import SessionIntent from './meta/SessionIntent'
import LongTermProfile from './meta/LongTermProfile';
import QueryIntent from './meta/QueryIntent'
import { Stats } from './Stats';
import {branch, build} from './version';
import { useHarderState } from './HarderProvider';
import UserLocation from './meta/UserLocation';
import Searchbox from './Searchbox'
import QueryPrimes from './meta/QueryPrime';
import ResultList from './results/ResultList';
import LeaderLines from './results/LeaderLines';
import ExtensionVersion from './ExtensionVersion';
import ScoreCompare from './results/ScoreCompare';
import Toggles from './meta/Toggles';
import GoogleResults from './results/GoogleResults';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { appInsights, appInsightsPlugin } from './appInsights';
import Progress from './meta/Progress';
import PromptPicker from './prompts/PromptPicker';
import SearchHistory from './meta/SearchHistory';
import WapiHistory from './user/Wapi';


function App() {
  const { instance, accounts } = useMsal();
  const [ harder, ] = useHarderState();

  useEffect(() => {
    if (harder.q) {
      appInsights.trackPageView({
        name: 'SERP',
        properties: {
          q: harder.q,
          user: accounts.length > 0 ? accounts[0].name : "anonymous",
          language: harder.language,
          region: harder.region,
          location: harder.location,
          disablePersonalization: harder.disablePersonalization,
          disableGoogle: harder.disableGoogle,
          disableGenCaptions: harder.disableGenCaptions,
          disableQprimes: harder.disableQprimes,
          disableDupePenalty: harder.disableDupePenalty,
          resultsFrom: harder.resultsFrom,
          prompts_qprime: harder.prompts.qprimes,
          prompts_intent: harder.prompts.intent
        }
      })
    }
  }, [harder.q])

  return (
    <div className="App">
      <div className="header">
        Search Harder
        <Searchbox></Searchbox>
        <div>
          Questions to <a href="mailto:aoakley@microsoft.com">aoakley</a>.
        </div>
      </div>
      <ExtensionCheck></ExtensionCheck>
      {harder.extensionVersion && <>
        <div className="scores">
          <ScoreCompare left={harder.original_results} leftField="original" right={harder.results} rightField="dcg" rightReverse={true} penalizeDupes={!harder.disableDupePenalty}></ScoreCompare>
        </div>
        <div>
        </div>
        <div className="results">
          {harder.q && <ResultList results={harder.original_results} prefix="old" sortField="original"></ResultList>}
          {harder.q && <SerpList></SerpList>}
        </div>
        <div className="results">
          {harder.q && <ResultList results={harder.results} prefix="new" sortField="dcg" reverse={true} penalizeDupes={!harder.disableDupePenalty}></ResultList>}
        </div>
        <LeaderLines results={harder.results}></LeaderLines>
        <div className="meta">
          <Progress></Progress>

          {harder.q && <>
            <h2>Query intent</h2>
            {!harder.disableGoogle && <GoogleResults></GoogleResults>}
            <QueryIntent></QueryIntent>
            {!harder.disableQprimes && <QueryPrimes></QueryPrimes>}
          </>}
          
          <h2>User information</h2>
          <UserLocation></UserLocation>
          {!harder.disablePersonalization && <>
            <SessionIntent></SessionIntent>
            <LongTermProfile></LongTermProfile>
          </>}
          <SearchHistory></SearchHistory>
          <h2>Configuration</h2>
          <Toggles></Toggles> 

          <h2>Prompt configuration</h2>
          <PromptPicker></PromptPicker>
        </div>
      </>}
      <div className="footer">
        <Stats></Stats>
        <div>App {branch + "-" + build}</div>
        <div><ExtensionVersion></ExtensionVersion></div>
        <button onClick={() => instance.logoutRedirect()}>Logout</button>
        <WapiHistory></WapiHistory>
      </div>
    </div>
  );
}

export default withAITracking(appInsightsPlugin, App);
