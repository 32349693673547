import Completion from "../gpt/Completion";
import { useEffect, useState } from "react";
import { useHarderState } from "../HarderProvider";
import Markdown from "react-markdown";
import { GetPrompt } from "../prompts/inventory";

function QueryIntent() {
    const [harder, dispatch] = useHarderState();

    const [intent, setIntent] = useState(null);
    const [prompt, setPrompt] = useState(null);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        // check dependencies first
        if (harder.original_results.length === 0) return;
        if (!harder.google_results && !harder.disableGoogle) return;
        if (!harder.ltProfile && !harder.disablePersonalization) return;
        
        // line below should not be commented out (it prevents double load) but currently personalized prompts require it
        // if (!harder.sessionIntent && !harder.disablePersonalization) return;

        GetPrompt("intent", harder.prompts.intent).then(([prompt_func, parser_func]) => {
            setPrompt(prompt_func(harder));
        });
    }, [harder.original_results, harder.google_results, harder.disablePersonalization, harder.disableGoogle, harder.ltProfile, harder.sessionIntent]);

    const parseResults = (results) => {
        GetPrompt("intent", harder.prompts.intent).then(([prompt_func, parser_func]) => {
            const parsed_options = parser_func(results)
            dispatch({type: 'set', key: 'intent', value: parsed_options[0]});
            setOptions(parsed_options);
        });
    }

    return (
        <>
            {prompt && <Completion name="intent" prompt={prompt} onReceive={setIntent} onDone={parseResults}></Completion>}
            {!harder.intent && <Markdown>{intent}</Markdown>}
            <fieldset>

            {options.map(opt => <>
            <tr>
                <td>
                    <input type="radio" name="intent" checked={opt === harder.intent} onChange={e => {
                        if (e.currentTarget.value) {
                            dispatch({type: 'set', key: 'intent', value: opt});
                            dispatch({type: 'set', key: 'qprimes', value: null});
                            dispatch({type: 'clearScores'})                        
                        }
                    }}></input>
                </td>
                <td>
                    <Markdown>{opt}</Markdown>
                </td>
            </tr>
                </>
                )}
            </fieldset>

        </>
    );
}

export default QueryIntent;

