/* global chrome */
import { useState, useEffect } from "react"
import { useMsal } from "@azure/msal-react";

export default function WhoAmI() {
    const [cookies, setCookies] = useState([]);
    const [anid, setAnid] = useState();
    const [muid, setMuid] = useState();

    const {instance, accounts} = useMsal();

    useEffect(() => {
        if (chrome && chrome.runtime) {
            chrome.runtime.sendMessage(process.env.REACT_APP_EXTENSION_ID, { type: 'getCookies', domain: 'bing.com' }, (response) => { 
                setCookies(response);
                for (let cookie of response) {
                    if (cookie.name === "MUID") { setMuid(cookie.value) }
                    if (cookie.name === "ANON") { 
                        const usp = new URLSearchParams(cookie.value)
                        setAnid(usp.get("A"))
                    }
                }
            });
        }
    }, []);

    return <>
        <h1>Who Am I</h1>

        <h2>Bing details</h2>

        <table>
            <tr>
                <td>ANID</td>
                <td>{anid}</td>
            </tr>
            <tr>
                <td>MUID</td>
                <td>{muid}</td>
            </tr>
        </table>

        <h2>Search Harder auth</h2>

        <table>
            <tr>
                <td>Account name</td>
                <td>{accounts[0].name}</td>
            </tr>
            <tr>
                <td>Account username</td>
                <td>{accounts[0].username}</td>
            </tr>
            <tr>
                <td>Tenant</td>
                <td>{accounts[0].tenantId}</td>
            </tr>
            <tr>
                <td>Account ID</td>
                <td>{accounts[0].localAccountId}</td>
            </tr>

        </table>

        <hr />

        <h2>Bing.com cookies</h2>

        <table>
            {cookies.map(cookie => 
                <tr>
                    <td>{cookie.name}</td>
                    <td>{cookie.value}</td>
                </tr>
            )}
        </table>
    </>
}