import Result from "./Result";
const { distance } = require('fastest-levenshtein')

function getDomainWithoutSubdomain(url) {
    const urlParts = new URL(url).hostname.split('.');
    return urlParts.slice(0).slice(-(urlParts.length === 4 ? 3 : 2)).join('.');
}

export function SortResults(results, sortField, reverse, penalizeDupes) {
    // create a clone of those results that have the sort field
    let algos = JSON.parse(JSON.stringify(results.filter(x => x[sortField])));
    algos.sort((a, b) => (reverse ? -1 : 1) * (a[sortField] - b[sortField]))

    for (let i = 0; i < algos.length; i++) {
        let scaler = 1;
        for (let j = 0; j < i; j++) {
            if (getDomainWithoutSubdomain(algos[j].url) === getDomainWithoutSubdomain(algos[i].url) || distance(algos[j].url, algos[i].url) * 1.0 / algos[i].url.length < 0.2) {
                scaler *= 0.5;
            }
        }
        if (scaler < 1) {
            algos[i].dupe_multiplier = scaler;
        }
    }

    if (penalizeDupes) {
        algos.sort((a, b) => (reverse ? -1 : 1) * (a[sortField] * (a.dupe_multiplier || 1) - b[sortField] * (b.dupe_multiplier || 1)))
    }

    return algos;
}


function ResultList({ results, prefix, sortField, reverse, penalizeDupes }) {
    const algos = SortResults(results, sortField, reverse, penalizeDupes);

    return (
        <div>
            {algos.map((result, i) => <Result {...result} prefix={prefix} key={result.url}></Result>)}
        </div>
    )
}


export default ResultList