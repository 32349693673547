import Xarrow from 'react-xarrows'

function LeaderLines({results}) {

    return (<>
        {results.map(r => {
           if (document.getElementById("old"+r.urlhash) && document.getElementById("new"+r.urlhash))
                return <Xarrow key={r.urlhash} 
                    showHead={false} curveness={0.5} strokeWidth={1} dashness={{ strokeLen:1, nonStrokeLen:1 }} 
                    startAnchor="right" endAnchor="left" 
                    start={"old"+r.urlhash} end={"new"+r.urlhash}></Xarrow> 
            else return <></>;
        })}

    </>)
}

export default LeaderLines